'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var _createClass = function () {
    function defineProperties(target, props) {
        for (var i = 0; i < props.length; i++) {
            var descriptor = props[i];
            descriptor.enumerable = descriptor.enumerable || false;
            descriptor.configurable = true;
            if ('value' in descriptor)
                descriptor.writable = true;
            Object.defineProperty(target, descriptor.key, descriptor);
        }
    }
    return function (Constructor, protoProps, staticProps) {
        if (protoProps)
            defineProperties(Constructor.prototype, protoProps);
        if (staticProps)
            defineProperties(Constructor, staticProps);
        return Constructor;
    };
}();
var _lrc = require('../template/lrc.art');
var _lrc2 = _interopRequireDefault(_lrc);
function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : { default: obj };
}
function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
var Lrc = function () {
    function Lrc(options) {
        _classCallCheck(this, Lrc);
        this.container = options.container;
        this.async = options.async;
        this.player = options.player;
        this.parsed = [];
        this.index = 0;
        this.current = [];
    }
    _createClass(Lrc, [
        {
            key: 'show',
            value: function show() {
                this.player.events.trigger('lrcshow');
                this.player.template.lrcWrap.classList.remove('aplayer-lrc-hide');
            }
        },
        {
            key: 'hide',
            value: function hide() {
                this.player.events.trigger('lrchide');
                this.player.template.lrcWrap.classList.add('aplayer-lrc-hide');
            }
        },
        {
            key: 'toggle',
            value: function toggle() {
                if (this.player.template.lrcWrap.classList.contains('aplayer-lrc-hide')) {
                    this.show();
                } else {
                    this.hide();
                }
            }
        },
        {
            key: 'update',
            value: function update() {
                var currentTime = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.player.audio.currentTime;
                if (this.index > this.current.length - 1 || currentTime < this.current[this.index][0] || !this.current[this.index + 1] || currentTime >= this.current[this.index + 1][0]) {
                    for (var i = 0; i < this.current.length; i++) {
                        if (currentTime >= this.current[i][0] && (!this.current[i + 1] || currentTime < this.current[i + 1][0])) {
                            this.index = i;
                            this.container.style.transform = 'translateY(' + -this.index * 16 + 'px)';
                            this.container.style.webkitTransform = 'translateY(' + -this.index * 16 + 'px)';
                            this.container.getElementsByClassName('aplayer-lrc-current')[0].classList.remove('aplayer-lrc-current');
                            this.container.getElementsByTagName('p')[i].classList.add('aplayer-lrc-current');
                        }
                    }
                }
            }
        },
        {
            key: 'switch',
            value: function _switch(index) {
                var _this = this;
                if (!this.parsed[index]) {
                    if (!this.async) {
                        if (this.player.list.audios[index].lrc) {
                            this.parsed[index] = this.parse(this.player.list.audios[index].lrc);
                        } else {
                            this.parsed[index] = [[
                                    '00:00',
                                    'Not available'
                                ]];
                        }
                    } else {
                        this.parsed[index] = [[
                                '00:00',
                                'Loading'
                            ]];
                        var xhr = new XMLHttpRequest();
                        xhr.onreadystatechange = function () {
                            if (index === _this.player.list.index && xhr.readyState === 4) {
                                if (xhr.status >= 200 && xhr.status < 300 || xhr.status === 304) {
                                    _this.parsed[index] = _this.parse(xhr.responseText);
                                } else {
                                    _this.player.notice('LRC file request fails: status ' + xhr.status);
                                    _this.parsed[index] = [[
                                            '00:00',
                                            'Not available'
                                        ]];
                                }
                                _this.container.innerHTML = (0, _lrc2.default)({ lyrics: _this.parsed[index] });
                                _this.update(0);
                                _this.current = _this.parsed[index];
                            }
                        };
                        var apiurl = this.player.list.audios[index].lrc;
                        xhr.open('get', apiurl, true);
                        xhr.send(null);
                    }
                }
                this.container.innerHTML = (0, _lrc2.default)({ lyrics: this.parsed[index] });
                this.update(0);
                this.current = this.parsed[index];
            }
        },
        {
            key: 'parse',
            value: function parse(lrc_s) {
                if (lrc_s) {
                    lrc_s = lrc_s.replace(/([^\]^\n])\[/g, function (match, p1) {
                        return p1 + '\n[';
                    });
                    var lyric = lrc_s.split('\n');
                    var lrc = [];
                    var lyricLen = lyric.length;
                    for (var i = 0; i < lyricLen; i++) {
                        var lrcTimes = lyric[i].match(/\[(\d{2}):(\d{2})(\.(\d{2,3}))?]/g);
                        var lrcText = lyric[i].replace(/.*\[(\d{2}):(\d{2})(\.(\d{2,3}))?]/g, '').replace(/<(\d{2}):(\d{2})(\.(\d{2,3}))?>/g, '').replace(/^\s+|\s+$/g, '');
                        if (lrcTimes) {
                            var timeLen = lrcTimes.length;
                            for (var j = 0; j < timeLen; j++) {
                                var oneTime = /\[(\d{2}):(\d{2})(\.(\d{2,3}))?]/.exec(lrcTimes[j]);
                                var min2sec = oneTime[1] * 60;
                                var sec2sec = parseInt(oneTime[2]);
                                var msec2sec = oneTime[4] ? parseInt(oneTime[4]) / ((oneTime[4] + '').length === 2 ? 100 : 1000) : 0;
                                var lrcTime = min2sec + sec2sec + msec2sec;
                                lrc.push([
                                    lrcTime,
                                    lrcText
                                ]);
                            }
                        }
                    }
                    lrc = lrc.filter(function (item) {
                        return item[1];
                    });
                    lrc.sort(function (a, b) {
                        return a[0] - b[0];
                    });
                    return lrc;
                } else {
                    return [];
                }
            }
        }
    ]);
    return Lrc;
}();
exports.default = Lrc;